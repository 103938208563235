import classnames from "classnames";
import { Link } from "gatsby";
import noScroll from "no-scroll";
import React, { useRef, useState, useEffect } from "react";
import FocusLock from "react-focus-lock";

import UstwoLogo from "../../assets/UstwoLogo";
import { useOnClickOutside, useScrollPosition, useWindowSize, useScrollDirection } from "../../utils/hooks";
import * as styles from "./Header.module.css";
import MobileNavButton from "./MobileNavButton";

const Header = ({ invert, hideNav, hideLogo, tokyo, removeHeaderBackground, homeInvert }) => {
  /* Mobile nav */
  const [open, setOpen] = useState(false);
  const headerRef = useRef();

  const toggleMobileMenu = (bool) => {
    if (bool) {
      noScroll.on();
    } else {
      noScroll.off();
    }
    setOpen(bool);
  };

  useOnClickOutside(headerRef, () => toggleMobileMenu(false));

  const windowHeight = useWindowSize().height;
  const scrollY = useScrollPosition();
  // const scrollPages = Math.round((scrollY / windowHeight) * 100) / 100;

  const scrollDirection = useScrollDirection();

  const [scrollPages, setScrollPages] = useState(0);

  useEffect(() => {
    setScrollPages(Math.round((scrollY / windowHeight) * 100) / 100);
  });

  /* Classes */
  const headerClasses = classnames(styles.header, {
    [styles.invert]: (invert && scrollPages < 0.9) || (homeInvert && scrollPages < 0.9),
    [styles.removeHeaderBackground]: (removeHeaderBackground && scrollPages === 0) || (homeInvert && scrollPages < 0.9),
    [styles.hideNav]: hideNav || (tokyo && scrollPages < 0.9),
    [styles.hideLogo]: hideLogo || (tokyo && scrollPages < 0.9),
    [styles.headerWrapperMenuOpen]: open,
    // [styles.hidden]: scrollDirection === "down"
  });
  const navClasses = classnames(styles.navWrapper, {
    [styles.menuOpen]: open,
  });

  const content = (
    <header className={headerClasses} ref={headerRef}>
      <div className={styles.inner}>
        <Link
          to={tokyo ? "/tokyo/" : "/"}
          className={styles.logo}
          onClick={() => toggleMobileMenu(false)}
        >
          <UstwoLogo />
        </Link>

        <a className={styles.skipLink} href="#main-content">Skip to content</a>
        
        {hideNav ? <></> : (
          <div className={styles.mobileNavButton}>
            <MobileNavButton
              toggleMobileMenu={toggleMobileMenu}
              open={open}
              aria-controls="nav"
              invert={(homeInvert && scrollPages < 0.9 || invert && scrollPages < 0.9)}
            />
          </div>
        )}

        {tokyo ? <></> : (
          <div className={navClasses}>
            <nav className={styles.nav} id="nav">
              <ul>
                <li>
                  <Link
                    className={styles.navLink}
                    to="/what-we-do/"
                    onClick={() => toggleMobileMenu(false)}
                    activeClassName={styles.activeLink}
                    partiallyActive
                  >
                    What we do
                  </Link>
                </li>
                <li>
                  <Link
                    className={styles.navLink}
                    to="/work/"
                    onClick={() => toggleMobileMenu(false)}
                    activeClassName={styles.activeLink}
                    partiallyActive
                  >
                    Work
                  </Link>
                </li>
                <li>
                  <Link
                    className={styles.navLink}
                    to="/our-story/"
                    onClick={() => toggleMobileMenu(false)}
                    activeClassName={styles.activeLink}
                    partiallyActive
                  >
                    Our story
                  </Link>
                </li>
                <li>
                  <Link
                    className={styles.navLink}
                    to="/blog/"
                    onClick={() => toggleMobileMenu(false)}
                    activeClassName={styles.activeLink}
                    partiallyActive
                  >
                    Insights
                  </Link>
                </li>
                <li>
                  <Link
                    className={styles.navLink}
                    to="/join-us/"
                    onClick={() => toggleMobileMenu(false)}
                    activeClassName={styles.activeLink}
                    partiallyActive
                  >
                    Join us
                  </Link>
                </li>
                <li>
                  <Link
                    className={styles.navLink}
                    to="/contact-us/"
                    onClick={() => toggleMobileMenu(false)}
                    activeClassName={styles.activeLink}
                    partiallyActive
                  >
                    Let's chat
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </header>
  );

  if (open) {
    return (
      <FocusLock disabled={!open}>
        {content}
      </FocusLock>
    );
  }
  return content;

};

export default Header;
