import "../../styles/variables.css";
import "../../styles/normalize.css";
import "../../styles/index.css";
import "../../styles/typography.css";

import { graphql } from "gatsby";
import React, { useState, useEffect } from "react";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import { useWindowSize } from "../../utils/hooks";

const Layout = ({ children, location }) => {
  const windowWidth = useWindowSize().width;
  const isMobile = windowWidth < 1024;

  // const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   setIsMobile(windowWidth < 1024);
  // }, [])

  const invertHeader =
    (
      location.pathname === "/our-story/"
    );
  
  const removeHeaderBackground = 
    location.pathname === "/what-we-do/discover/" ||
    location.pathname === "/what-we-do/pilot/" ||
    location.pathname === "/what-we-do/launch/" ||
    location.pathname === "/what-we-do/boost/";

  const tokyoSection = location.pathname.includes("/tokyo/"); /* Tokyo has landing page and B Corp page */
  // const hideLogo = location.pathname === "/tokyo/"; /* Different header layout for Tokyo landing page means let's remove logo in header */
    
  return (
    <>
      <Header
        removeHeaderBackground={removeHeaderBackground}
        // invert={invertHeader || casestudyInvert}
        homeInvert={location.pathname === "/" || location.pathname === "/tokyo/"}
        hideNav={tokyoSection}
        tokyo={tokyoSection}
      />
      <main id="main-content">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;

export const query = graphql`
  fragment ChunkFields on ContentfulChunk {
    id
    name
    title
    image {
      id
      title
      file {
        url
      }
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
    }
    gallery {
      id
      title
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      file {
        url
      }
    }
    video {
      ...VideoFields
    }
    content {
      childMarkdownRemark {
        html
      }
    }
    furtherContent {
      childMarkdownRemark {
        html
      }
    }
    list {
      ... on ContentfulListItem {
        id
        title
        content {
          childMarkdownRemark {
            html
          }
        }
        image {
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          file {
            url
          }
        }
        reference {
          ... on ContentfulPost {
            id
            title
            description
            image {
              title
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          ... on ContentfulExternalLink {
            id
            title
            description
            image {
              title
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              file {
                url
                contentType
              }
            }
          }
          ... on ContentfulPage {
            id
            title
            description
            image {
              title
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
      }
      ... on ContentfulStudio {
        id
        addressLine1
        addressLine2
        city
        country
        telephone
        email
        gmap
        order
        photo {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 80)
        }
      }
      ... on ContentfulStat {
        id
        title
        copy
        star
      }
      ... on ContentfulExternalLink {
        id
        title
        description
        image {
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          file {
            url
            contentType
          }
        }
      }
    }
    reference {
      ... on ContentfulPost {
        id
        title
        description
        image {
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      ... on ContentfulExternalLink {
        id
        title
        description
        image {
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      ... on ContentfulPage {
        id
        name
        title
        description
        image {
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
    posts {
      ... on ContentfulPost {
        id
        title
        description
        image {
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      ... on ContentfulPage {
        id
        title
        description
        image {
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;
