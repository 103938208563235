import classnames from "classnames";
import React from "react";

import * as styles from "./Grid.module.css";

/* 
  Default is a two column layout (from small screen breakpoint and beyond).
*/

const Col = ({
  children,
  span,
  start,
  verticalStretch,
  className,
  smallMobileMargin,
  noBottomMargin,
}) => {
  const classes = classnames(styles.col, className, {
    [styles.verticalStretch]: verticalStretch,
    [styles.smallMobileMargin]: smallMobileMargin,
    [styles.noBottomMargin]: noBottomMargin,
  });

  return (
    <div
      className={classes}
      style={{ gridColumn: `${start || "1"} / span ${span || "12"}` }}
    >
      {children}
    </div>
  );
};

const Grid = ({
  children,
  className,
  columns,
  reverseOrder,
  padding,
  singleColumn,
  feature,
  verticalCenter,
  verticalEnd,
  verticalSpaceBetween,
  orderFix,
  style
}) => {
  const classes = classnames(styles.gridContainer, className, {
    [styles.oneColumn]: columns === 1,
    [styles.twoColumns]: columns === 2,
    [styles.threeColumns]: columns === 3,
    [styles.fourColumns]: columns === 4,
    [styles.fiveColumns]: columns === 5,
    [styles.reverseOrder]: reverseOrder,
    [styles.paddingTop]: padding === "top" || padding === "both",
    [styles.paddingBottom]: padding === "bottom" || padding === "both",
    [styles.singleColumn]: singleColumn,
    [styles.featureSmall]: feature === "small",
    [styles.featureLarge]: feature === "large",
    [styles.verticalCenter]: verticalCenter,
    [styles.verticalSpaceBetween]: verticalSpaceBetween,
    [styles.verticalEnd]: verticalEnd,
    [styles.orderFix]: orderFix,
  });

  return <div className={classes} style={style}>{children}</div>;
};

export { Col };
export default Grid;
