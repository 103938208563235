import React from "react";

const IconEmployeeOwned = ({ fill, width }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 990 419.5" fill={fill} width={width}>
    <title>Proudly employee owned</title>
    <g>
      <g>
        <path className="st3" d="M529.62,76.69c0,14.51-10.36,23.84-26.6,23.84h-18.54v32.24h-12.21V52.74h30.74
          C519.37,52.74,529.62,62.06,529.62,76.69z M517.18,76.69c0-7.83-5.18-13.24-14.86-13.24h-17.85v26.37h17.85
          C512,89.81,517.18,84.4,517.18,76.69z"/>
        <path className="st3" d="M565.89,70.7v11.4c-10.82,0.11-17.96,6.56-17.96,15.08v35.58h-12.21V72.08h11.52v7.72
          C551.26,74.61,557.94,70.7,565.89,70.7z"/>
        <path className="st3" d="M568.94,102.48c0-18.19,13.24-31.66,31.89-31.66c18.77,0,32.01,13.47,32.01,31.66
          c0,18.08-13.24,31.66-32.01,31.66C582.18,134.14,568.94,120.56,568.94,102.48z M620.64,102.48c0-12.21-7.94-20.84-19.69-20.84
          c-11.74,0-19.8,8.64-19.8,20.84c0,11.98,8.06,20.73,19.8,20.73C612.58,123.2,620.64,114.57,620.64,102.48z"/>
        <path className="st3" d="M693.75,72.08v60.68h-11.4v-7.72c-4.14,5.3-11.05,9.1-19.46,9.1c-15.54,0-23.95-10.36-23.95-24.52V72.08
          h12.09v36.04c0,9.1,5.87,15.2,15.2,15.08c9.21,0,15.31-6.56,15.43-13.7V72.08H693.75z"/>
        <path className="st3" d="M762.49,48.59v84.17h-11.28v-9.44c-4.72,6.79-12.21,10.82-21.42,10.82c-17.39,0-29.94-13.59-29.94-31.78
          c0-17.96,12.32-31.55,29.59-31.55c8.75,0,16.01,3.57,20.84,9.67V48.59H762.49z M750.75,102.36c0-12.21-7.83-20.84-19.46-20.84
          c-11.05,0-19.34,8.98-19.34,20.84c0,12.09,8.18,20.96,19.34,20.96C742.8,123.32,750.75,114.68,750.75,102.36z"/>
        <path className="st3" d="M771.65,48.59h12.21v84.17h-12.21V48.59z"/>
        <path className="st3" d="M851.67,72.08l-23.95,60.68l-8.06,20.61h-11.97l8.29-20.61h-3.45l-22.57-60.68h12.55l17.62,50.66l18.88-50.66
          H851.67z"/>
        <path className="st3" d="M503.01,240.31c9.1,0,14.16-3.34,16.93-9.67l10.02,6.33c-4.6,8.06-12.78,14.16-27.29,14.16
          c-19.11,0-31.78-13.59-31.78-31.66c0-17.62,13.7-31.78,30.86-31.78c18.77,0,30.4,14.74,30.4,30.4c0,2.07-0.11,4.26-0.23,5.41
          h-48.93C484.82,233.86,492.77,240.31,503.01,240.31z M520.17,214.17c-1.61-10.25-8.64-16.58-17.96-16.58
          c-9.44,0-17.27,6.1-19.11,16.58H520.17z"/>
        <path className="st3" d="M635,212.21v37.54h-12.09V214.4c0-9.1-5.3-15.89-14.51-15.77c-9.33,0-14.74,7.25-14.85,13.59v37.54h-12.21
          V214.4c0-9.1-5.3-15.89-14.51-15.77c-9.21,0-14.74,7.14-14.85,13.59v37.54h-12.21v-60.68h11.52v7.72c3.8-5.07,10.48-9.1,19.11-9.1
          c10.13,0,16.58,4.14,20.03,11.17c3.22-6.1,10.36-11.17,21.07-11.17C626.59,187.69,635,198.28,635,212.21z"/>
        <path className="st3" d="M706.79,219.47c0,18.08-12.67,31.66-29.94,31.66c-8.75,0-15.77-3.45-20.5-9.44v28.67h-12.21v-81.29h11.28
          v9.56c4.84-6.79,12.32-10.82,21.65-10.82C694.47,187.8,706.79,201.39,706.79,219.47z M694.7,219.47
          c0-12.09-8.18-20.96-19.35-20.96c-11.51,0-19.57,8.64-19.57,20.96c0,12.32,7.95,20.84,19.57,20.84
          C686.41,240.31,694.7,231.44,694.7,219.47z"/>
        <path className="st3" d="M714.42,165.58h12.21v84.17h-12.21V165.58z"/>
        <path className="st3" d="M734.25,219.47c0-18.19,13.24-31.66,31.9-31.66c18.77,0,32.01,13.47,32.01,31.66
          c0,18.08-13.24,31.66-32.01,31.66C747.5,251.13,734.25,237.54,734.25,219.47z M785.95,219.47c0-12.21-7.95-20.84-19.69-20.84
          c-11.75,0-19.81,8.64-19.81,20.84c0,11.98,8.06,20.73,19.81,20.73C777.89,240.19,785.95,231.56,785.95,219.47z"/>
        <path className="st3" d="M859.88,189.07l-23.95,60.68l-8.06,20.61h-11.97l8.29-20.61h-3.45l-22.57-60.68h12.55l17.62,50.66
          l18.88-50.66H859.88z"/>
        <path className="st3" d="M892,240.31c9.09,0,14.16-3.34,16.92-9.67l10.02,6.33c-4.61,8.06-12.78,14.16-27.29,14.16
          c-19.11,0-31.78-13.59-31.78-31.66c0-17.62,13.7-31.78,30.86-31.78c18.77,0,30.4,14.74,30.4,30.4c0,2.07-0.11,4.26-0.23,5.41
          h-48.94C873.81,233.86,881.75,240.31,892,240.31z M909.16,214.17c-1.61-10.25-8.64-16.58-17.96-16.58
          c-9.44,0-17.27,6.1-19.11,16.58H909.16z"/>
        <path className="st3" d="M960.89,240.31c9.1,0,14.16-3.34,16.93-9.67l10.02,6.33c-4.6,8.06-12.78,14.16-27.29,14.16
          c-19.11,0-31.78-13.59-31.78-31.66c0-17.62,13.7-31.78,30.86-31.78c18.77,0,30.4,14.74,30.4,30.4c0,2.07-0.11,4.26-0.23,5.41
          h-48.94C942.69,233.86,950.64,240.31,960.89,240.31z M978.04,214.17c-1.61-10.25-8.64-16.58-17.96-16.58
          c-9.44,0-17.27,6.1-19.11,16.58H978.04z"/>
        <path className="st3" d="M470.89,336.45c0-18.19,13.24-31.66,31.89-31.66c18.77,0,32.01,13.47,32.01,31.66
          c0,18.08-13.24,31.66-32.01,31.66C484.13,368.12,470.89,354.53,470.89,336.45z M522.59,336.45c0-12.21-7.94-20.84-19.69-20.84
          c-11.74,0-19.8,8.64-19.8,20.84c0,11.98,8.06,20.73,19.8,20.73C514.53,357.18,522.59,348.54,522.59,336.45z"/>
        <path className="st3" d="M626.05,306.06l-16.24,60.68h-14.39l-13.47-47.32l-13.24,47.32h-14.39l-16.47-60.68h12.55l11.63,48.94
          l13.13-48.94h13.7l13.13,48.48l11.52-48.48H626.05z"/>
        <path className="st3" d="M688.11,329.32v37.42H675.9V331.5c0-10.02-5.99-16.01-15.66-15.89c-9.09,0-15.77,6.68-15.89,14.16v36.96
          h-12.21v-60.68h11.51v7.72c4.15-5.3,11.05-9.1,20.03-9.1C678.9,304.67,688.11,314.46,688.11,329.32z"/>
        <path className="st3" d="M727.86,357.29c9.1,0,14.16-3.34,16.93-9.67l10.02,6.33c-4.6,8.06-12.78,14.16-27.29,14.16
          c-19.11,0-31.78-13.59-31.78-31.66c0-17.62,13.7-31.78,30.86-31.78c18.77,0,30.4,14.74,30.4,30.4c0,2.07-0.11,4.26-0.23,5.41
          h-48.93C709.67,350.85,717.61,357.29,727.86,357.29z M745.02,331.16c-1.61-10.25-8.64-16.58-17.96-16.58
          c-9.44,0-17.27,6.1-19.11,16.58H745.02z"/>
        <path className="st3" d="M827.26,282.57v84.17h-11.28v-9.44c-4.72,6.79-12.21,10.82-21.42,10.82c-17.39,0-29.94-13.59-29.94-31.78
          c0-17.96,12.32-31.55,29.59-31.55c8.75,0,16.01,3.57,20.84,9.67v-31.89H827.26z M815.52,336.34c0-12.21-7.83-20.84-19.46-20.84
          c-11.05,0-19.34,8.98-19.34,20.84c0,12.09,8.17,20.96,19.34,20.96C807.57,357.29,815.52,348.66,815.52,336.34z"/>
        <path className="st3" d="M834.89,360.29c0-4.49,3.34-7.72,7.6-7.72c4.38,0,7.6,3.23,7.6,7.72c0,4.26-3.23,7.48-7.6,7.48
          C838.23,367.77,834.89,364.55,834.89,360.29z"/>
      </g>
      <g>
        <path className="st3" d="M209.78,105.48c-48.48,0-89.23,33.07-100.91,77.9h201.81C299.01,138.55,258.26,105.48,209.78,105.48z"/>
        <path className="st3" d="M209.76,0C93.91,0,0,93.91,0,209.76c0,115.85,93.91,209.76,209.76,209.76s209.76-93.91,209.76-209.76
          C419.53,93.91,325.61,0,209.76,0z M209.78,366.79c-43.36,0-82.61-17.58-111.03-46l37.29-37.29
          c18.86,18.86,44.94,30.54,73.73,30.54c28.8,0,54.87-11.68,73.74-30.54l37.29,37.29C292.39,349.21,253.14,366.79,209.78,366.79z
          M54.97,236.13c-1.46-8.57-2.21-17.38-2.21-26.36c0-9,0.75-17.81,2.21-26.38c12.54-74.17,77.08-130.64,154.81-130.64
          c77.73,0,142.27,56.47,154.81,130.64c1.45,8.57,2.21,17.38,2.21,26.38c0,8.98-0.75,17.79-2.21,26.36H54.97z"/>
      </g>
    </g>
  </svg>
);

export default IconEmployeeOwned;
